<template>
  <div class="container mt-4">
    <h2 class="text-center mb-4 text-primary" v-if="eventDetails">Event Details</h2>

    <!-- Loading Indicator for Event Details -->
    <div v-if="eventLoading" class="alert alert-info text-center">
      Loading event details...
    </div>

    <!-- Error Message for Event -->
    <div v-if="eventError" class="alert alert-danger text-center">
      {{ eventError }}
    </div>

    <!-- Event Information -->
    <div v-if="!eventLoading && eventDetails" class="event-container">
      <h2 class="event-title text-success">{{ eventDetails.subject }}</h2>

      <div class="event-detail">
        <span class="label">Organizer :</span>
        <span class="value">{{ eventDetails.organizer.email }}</span>
      </div>

      <div class="event-detail">
        <span class="label">Start Time :</span>
        <span class="value">{{ formatDate(eventDetails.start) }}</span>
      </div>
      <div class="event-detail">
        <span class="label">End Time :</span>
        <span class="value">{{ formatDate(eventDetails.end) }}</span>
      </div>

      <div class="event-detail">
        <span class="label">Location :</span>
        <span class="value">{{ eventDetails.location || "No location" }}</span>
      </div>

      <div class="event-detail">
        <span class="label">Attendees :</span>
        <span class="value">
          <span v-if="eventDetails.attendees.length">
            <span
              v-for="(attendee, index) in eventDetails.attendees"
              :key="index"
            >
              {{ attendee.email
              }}{{ index &lt; eventDetails.attendees.length - 1 ? ", " : "" }}
            </span>
          </span>
          <span v-else class="text-muted">No attendees</span>
        </span>
      </div>

      <div class="event-detail">
        <span class="label">Created By :</span>
        <span class="value">{{ eventDetails.organizer.email }}</span>
      </div>

      <div class="event-detail">
        <span class="label">Last Modified :</span>
        <span class="value">{{ formatDate(eventDetails.lastModified) }}</span>
      </div>

      <div class="event-detail">
        <span class="label">Status :</span>
        <span class="value">{{ eventDetails.status }}</span>
      </div>

      <!-- <div v-if="eventDetails.onlineMeeting" class="event-detail">
        <span class="label">Meeting Link :</span>
        <span class="value text-primary"
          >{{ eventDetails.onlineMeeting?.joinUrl }}
          <el-tooltip content="Copy Meeting Link">
            <el-button
              icon="el-icon-document-copy"
              @click="copyMeetingLink(eventDetails.onlineMeeting?.joinUrl)"
              circle
            /> </el-tooltip
        ></span>
      </div> -->

      <!-- <div class="event-detail">
        <span class="label">Description:</span>
        <span
          class="value"
          v-html="eventDetails.description || 'No description'"
        ></span>
      </div> -->

      <!-- Online Meeting Button -->
      <div v-if="eventDetails.onlineMeeting" class="meeting-container">
        <a
          :href="eventDetails.onlineMeeting?.joinUrl"
          target="_blank"
          class="meeting-button"
        >
          🎥 Join Meeting
        </a>
      </div>

      <!-- Fetch Recordings Button -->
      <div class="recordings-container">
        <button
          @click="fetchRecordings"
          v-if="!recordingsFetched"
          class="recording-button"
        >
          Show Recordings
        </button>
      </div>
    </div>
    <!-- Fetch Recordings Button -->
    <!-- <div class="text-center mt-3">
      <button
        @click="fetchRecordings"
        v-if="!recordingsFetched"
        class="btn btn-primary"
      >
        Show Recordings
      </button>
    </div> -->

    <!-- Loading Indicator for Recordings -->
    <div v-if="loading" class="alert alert-info text-center">
      Loading recordings...
    </div>

    <!-- Error Message for Recordings -->
    <div v-if="error" class="alert alert-danger text-center">
      {{ error }}
    </div>

    <!-- Recordings List -->
    <div
      v-if="recordingsFetched && !loading && recordings?.length > 0"
      class="recordings-grid"
    >
      <div
        v-for="recording in recordings"
        :key="recording.id"
        class="recording-card"
      >
        <div class="card shadow-sm">
          <div class="card-body">
            <h5 class="card-title">{{ recording.name }}</h5>
            <p class="card-text">
              <strong>Created By:</strong>
              {{ recording.createdBy.user.displayName }}
            </p>
            <p class="card-text">
              <strong>Created On:</strong>
              {{ formatDate(recording.createdDateTime) }}
            </p>
            <div class="btn-group">
              <button @click="openVideo(recording['@microsoft.graph.downloadUrl'])" class="view-button">
                View
              </button>
              <a
                :href="recording['@microsoft.graph.downloadUrl']"
                target="_blank"
                class="download-link"
              >
                Download
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <p
      v-else-if="recordingsFetched && !loading && !error"
      class="text-center text-muted"
    >
      No recordings available
    </p>

    <!-- Video Player Modal -->
    <div
      v-if="showVideoModal"
      class="modal fade show d-block"
      tabindex="-1"
      role="dialog"
      @click.self="closeModal"
    >
      <div class="modal-dialog modal-lg modal-responsive" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Video Player</h5>
            <button type="button" class="close" @click="closeModal">
              &times;
            </button>
          </div>
          <div class="modal-body">
            <video controls autoplay class="w-100">
              <source :src="currentVideoUrl" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getAccounts,
  getConfigurations,
  fetchMeetingRecordings,
  fetchEventDetails,
} from "../user-integrations/KF-mail/data/messages";

export default {
  props: ["entityId", "entityDataId"],
  data() {
    return {
      eventDetails: null,
      eventLoading: true,
      eventError: null,
      recordings: [],
      loading: false,
      error: null,
      showVideoModal: false,
      currentVideoUrl: "",
      recordingsFetched: false,
      companyMailConfig: {},
      loginUserMailConfig: {},
    };
  },
  async created() {
    await this.fetchEventDetails();
  },
  methods: {
    async fetchEventDetails() {
      this.eventLoading = true;
      this.eventError = null;
      try {
        this.companyMailConfig = await getAccounts();
        this.loginUserMailConfig = await getConfigurations();
        if (this.companyMailConfig && this.loginUserMailConfig) {
          let response = await fetchEventDetails(
            this.companyMailConfig?.service,
            this.loginUserMailConfig?._id,
            this.entityId,
            this.entityDataId
          );
          if (response) {
            this.eventDetails = response.event;
          } else {
            this.eventLoading = false;
            this.eventError = "No Event Found";
          }
        }else{
          this.eventError = "The KF mail configuration needs to be set up.";
          this.eventLoading = false;
        }
      } catch (err) {
        this.eventLoading = false;
        this.eventError = "Failed to load event details.";
      } finally {
        this.eventLoading = false;
      }
    },
    async fetchRecordings() {
      this.recordingsFetched = true;
      this.loading = true;
      this.error = null;
      try {
        // this.companyMailConfig = await getAccounts();
        // this.loginUserMailConfig = await getConfigurations();
        if (this.companyMailConfig?.service && this.loginUserMailConfig?._id) {
          let response = await fetchMeetingRecordings(
            this.companyMailConfig?.service,
            this.loginUserMailConfig?._id,
            this.entityId,
            this.entityDataId
          );
          this.recordings = response ? response.recordings : [];
        } else {
         this.errorMessage = "The KF mail configuration needs to be set up.";
          this.eventLoading = false;
          this.loading = false
        }
      } catch (err) {
        this.error = "Failed to fetch recordings. Please try again.";
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
    copyMeetingLink(link) {
      if (!link) return;
      navigator.clipboard
        .writeText(link)
        .then(() => {
          this.$message.success("Meeting link copied!");
        })
        .catch(() => {
          this.$message.error("Failed to copy link!");
        });
    },
    formatDate(dateString) {
      return new Date(dateString).toLocaleString("en-GB");
    },
    openVideo(url) {
      this.currentVideoUrl = url;
      this.showVideoModal = true;
    },
    closeModal() {
      this.showVideoModal = false;
      this.currentVideoUrl = "";
    },
  },
};
</script>

<style scoped>
/* Event Container */
.event-container {
  max-width: 1200px;
  width: 100%;
  margin: 20px auto;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.event-title {
  font-size: 22px;
  font-weight: bold;
  color: #333;
  margin-bottom: 15px;
  text-align: center;
}

.event-detail {
  display: grid;
  grid-template-columns: 1fr 2fr; /* Label takes 1 part, value takes 2 parts */
  gap: 8px;
  align-items: center;
  margin-bottom: 12px;
}

.label {
  font-weight: 600;
  color: #555;
  width: 120px; /* Fixed width ensures proper alignment */
  text-align: left;
}

.value {
  color: #333;
  flex: 2;
  flex-grow: 1;
  word-break: break-word;
}

.text-muted {
  color: #999;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.meeting-container {
  text-align: center;
  margin-top: 15px;
}

.meeting-button {
  display: inline-block;
  background: #007bff;
  color: white;
  font-weight: bold;
  padding: 8px 16px;
  border-radius: 5px;
  text-decoration: none;
  transition: background 0.3s;
}

.meeting-button:hover {
  background: #0056b3;
}

.recordings-container {
  text-align: center;
  margin-top: 10px;
}

.recording-button {
  background: #28a745;
  color: white;
  font-weight: bold;
  padding: 8px 16px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: background 0.3s;
}

.recording-button:hover {
  background: #218838;
}

/* Responsive Styles */
@media (max-width: 500px) {
  .event-container {
    padding: 15px;
  }

  .event-title {
    font-size: 18px;
  }

  .event-detail {
    flex-direction: column;
  }

  .grid-container {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 480px) {
  .event-title {
    font-size: 18px;
  }

  .value {
    font-size: 13px;
  }

  .meeting-button,
  .fetch-button {
    font-size: 14px;
    padding: 8px 12px;
  }
}

/* Recordings Grid */
.recordings-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 20px;
  justify-content: center;
  padding: 10px;
}

/* Card Styles */
.recording-card {
  display: flex;
  justify-content: center;
}

.card {
  border-radius: 10px;
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
}

.card:hover {
  transform: scale(1.03);
}

.card-body {
  text-align: left;
}

/* View Button */
/* Common Styles for Buttons */
.view-button,
.download-link {
  display: inline-block;
  margin-top: 5px;
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 3px;
  border: none;
  cursor: pointer;
  text-align: center;
}

/* View Button */
.view-button {
  background-color: #007bff;
  color: white;
  text-decoration: none;
}

.view-button:hover {
  background-color: #0056b3;
}

/* Download Button */
.download-link {
  background-color: #28a745;
  color: white;
  text-decoration: none;
  margin-left: 30%;
}

.download-link:hover {
  background-color: #1e7e34;
}

/* Modal Background */
.modal.fade.show {
  background: rgba(0, 0, 0, 0.5);
  display: flex !important;
  align-items: center;
  justify-content: center;
}

/* Modal Content */
.modal-content {
  border-radius: 10px;
  width: 100%;
  max-width: 800px;
}

/* Close Button */
.close {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  margin-left: auto; /* Push button to the right */
}

.close:hover {
  color: red;
}

/* Media Queries for Responsive Design */
@media (max-width: 1200px) {
  .recordings-grid {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
}

@media (max-width: 992px) {
  .recordings-grid {
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  }

  .modal-dialog {
    max-width: 90%;
  }
}

@media (max-width: 768px) {
  .recordings-grid {
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  }

  .modal-dialog {
    max-width: 95%;
  }

  .modal-content {
    padding: 10px;
  }

  .card-title {
    font-size: 1rem;
  }
  .view-button,
  .download-link {
    font-size: 11px;
    padding: 4px 8px;
  }
}

@media (max-width: 576px) {
  .recordings-grid {
    grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
  }

  .modal-dialog {
    max-width: 98%;
  }

  .modal-content {
    padding: 5px;
  }

  .close {
    font-size: 20px;
  }

  .view-button,
  .download-link {
    font-size: 10px;
    padding: 3px 6px;
  }
}
</style>
